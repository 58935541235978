import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-spinkit';
import { WebSocketComponent } from "./websocket_component"
import { TableComponent } from "./table_component"

export {  LeaderboardDashboard };



class LeaderboardDashboard extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {addr: this.props.addr, token: this.props.token, orderby: "day_7", dir: "desc", offset: "0", limit: "200", tableData: [['WETH', '$43,927.81', '0.1467', '$6,442.02'], ['ETH', '$2,345.85', '0.1060', '$248.64'], ['GMX', '$1.05', '10.0', '$123.12']], column_mapping: {"Encrypted ID": "binance_encrypted_id", "1 Day PNL": "day_1", "2 Day PNL": "day_2", "3 Day PNL": "day_3", "7 Day PNL": "day_7", "Lifetime PNL": "all_pnl", "Margin": "margin"}};

      if(this.state.token.length > 5)
      {  

        this.tableUpdate(this.props.token);
      }
    }


  componentDidUpdate(prevProps)
  {
    //This means that the props update and we might have a token now     
      if ((prevProps.token != this.props.token))
      {
        console.log("Updating token from " + this.state.token + " to " + this.props.token);
        this.setState({token: this.props.token});
        if (this.props.token != "")
        {

          this.tableUpdate(this.props.token, this.state.orderby, this.state.dir, this.state.offset);
        }
      }
  }


  tableUpdate(token, orderby = "day_7", dir = "desc", offset = "0", limit = "200")
  {
    console.log("Querying Top Leaderboard Accounts");
    fetch("https://panopticonia.com/api/query_top_table.php?token=" + token + "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            console.log("Queried Top Leaderboard Accounts");
            console.log(data);

            let table_data = [];

            for (let i = 0; i < data.length; i++)
            {
              let display_name = data[i][0];
              let display_name_type = 'encrypted_id';
              if((data[i][20] != null) && (data[i][20] != ""))
              {
                display_name = data[i][20];
                display_name_type = 'encrypted_id';
              }

              table_data.push([{data: parseInt(this.state.offset) + i, width: '25px', type: 'index'}, //The index
                              {data: display_name, width: '300px', type: display_name_type, extras: {userSearch: this.props.onNewSearch, ebid: data[i][0]}}, //ebid 
                              //{data: display_name, width: '300px', type: display_name_type, extras: {userSearch: "usersearchfunction"}}, //ebid 
                              {data: parseFloat(data[i][1]), width: '60px', type: 'pnl'}, //1 day pnl 
                              {data: parseFloat(data[i][3]), width: '60px', type: 'pnl'}, //2 day pnl
                              {data: parseFloat(data[i][5]), width: '60px', type: 'pnl'}, //3 day pnl
                              //{data: parseFloat(data[i][7]), width: '100px', type: 'pnl'}, //5 day pnl
                              {data: parseFloat(data[i][9]), width: '60px', type: 'pnl'}, //7 day pnl
                              //{data: parseFloat(data[i][11]), width:'100px', type: 'pnl'}, //14 day pnl
                              //{data: parseFloat(data[i][13]), width: '100px', type: 'pnl'}, //30 day pnl
                              {data: parseFloat(data[i][15]), width: '60px', type: 'pnl'}, //all time pnl
                              {data: parseFloat(data[i][17]), width: '60px', type: 'margin'}, //margin amount
                              //{data: parseFloat(data[i][16]), width: '100px', type: 'date'}, //margin date
                              {data: parseFloat(data[i][19]), width: '100px', type: 'date_relative'}]); //last trade checked
                              //{data: parseFloat(data[i][18]), width: '100px', type: 'date'}]); //last trade date
                              
            }
          
            console.log("Updating table state:");
            console.log(table_data);
            this.setState({tableData: table_data});
          });
  }


  nextClick = () =>
  {
    let nextOffset = (parseInt(this.state.offset) + parseInt(this.state.limit)).toString();
    this.setState({offset: nextOffset});
    console.log("Next Click: Offset = " + nextOffset);
    this.tableUpdate(this.props.token, this.state.orderby, this.state.dir, nextOffset);
  }

  previousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.offset) - parseInt(this.state.limit), 0)).toString();
    this.setState({offset: nextOffset});
    console.log("Previous Click: Offset = " + nextOffset);
    this.tableUpdate(this.props.token, this.state.orderby, this.state.dir, nextOffset);

  }

  sortClick = (column_name) =>
  {
    console.log("Sort click - " + column_name);
    console.log("Mapping: " + this.state.column_mapping);

    let sort_var = this.state.column_mapping[column_name];
    let sort_dir = 'DESC';

    if (sort_var == this.state.orderby)
    {
      if(this.state.dir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }

    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({orderby: this.state.column_mapping[column_name]});
    this.setState({dir: sort_dir});
    this.setState({offset: 0});
    
    console.log("New OrderBY: " + this.state.orderby);
    this.tableUpdate(this.props.token, sort_var, sort_dir, 0);

    /*
    if(column_name == 'Encrypted ID')
    {
      this.setState({orderby: "binance_encrypted_id"})
    }
    else if(column_name == '1 Day PNL')
    {
      this.setState({orderby: "day_1"})
    }
    else if(column_name == '2 Day PNL')
    {
      this.setState({orderby: "day_2"})
    }
    else if(column_name == '3 Day PNL')
    {
      this.setState({orderby: "day_3"})
    }
    else if(column_name == '7 Day PNL')
    {
      this.setState({orderby: "day_7"})
    }
    else if(column_name == 'Lifetime PNL')
    {
      this.setState({orderby: "all_pnl"})
    }
    else if(column_name == 'Margin')
    {
      this.setState({orderby: "margin"})
    }
    else if(column_name == 'Margin Update Time')
    {
      this.setState({orderby: "margin_date"})
    }
    else if(column_name == 'Last Trade Date')
    {
      this.setState({orderby: "last_trade_date"})
    }
    else if(column_name == 'Last Trade Updated')
    {
      this.setState({orderby: "last_trade_date_checked"})
    }
    */
  }



    render ()
    {
    
      console.log("Top Vips Render");
      console.log(this.state.tableData);

      let title = 'This is a sample table';
      let subtitle = '';
      let columns = [['#', '25px', 'Hidden'], ['Encrypted ID', '300px', 'Default'], ['1 Day PNL', '60px', 'Default'], ['2 Day PNL', '60px', 'Default'], ['3 Day PNL', '60px', 'Default'], ['7 Day PNL', '60px', 'Default'], ['Lifetime PNL', '60px', 'Default'], ['Margin', '100px', 'Default'], ['Last Trade Date', '100px', 'Default']];
    
      return (
          
          <div className = "vipPageBottomContainer">
            <TableComponent previousClick = {this.previousClick} nextClick = {this.nextClick} title = {title} subtitle = {"Top Traders Sorted By [" + this.state.orderby + "] [" + this.state.dir + "] Offset [" + this.state.offset + "]"} columns = {columns} data = {this.state.tableData} sort_click = {this.sortClick}></TableComponent>
          </div>
      );
    }
}
