import axios from "axios";
import React from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";

export { Login };

class Login extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {loginAPI: 'https://panopticonia.com/api/login.php?password='};
  }

  submitLoginForm = (event) => {
      event.preventDefault();
      const password_element = document.querySelector('#login-password');
      const btnPointer = document.querySelector('#login-btn');

      btnPointer.innerHTML = 'Please wait..';
      btnPointer.setAttribute('disabled', true);

      axios.get(this.state.loginAPI + password_element.value).then((response) => {
          btnPointer.innerHTML = 'Login';
          btnPointer.removeAttribute('disabled');
          
          if (response.data === "")
          {
            alert("Incorrect Password");
          }
          else
          {
            //alert("Correct Password, token: " + response.data);
            this.props.onLogin(response.data);
          }
      }).catch((error) => {
          btnPointer.innerHTML = 'Login';
          btnPointer.removeAttribute('disabled');
          alert("Error attempting to log in");
      });
  }

  render()
  {
    return (
        <React.Fragment>

            <Container className="my-5">
                <h2 className="fw-normal mb-5">Login to Panopticonia Application</h2>
                <Row>
                    <Col md={{span: 6}}>
                        <Form id="loginForm" onSubmit={this.submitLoginForm}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                <input type={'password'} className="form-control" id={'login-password'} name="password" required />
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2" id="login-btn">Login</Button>
                        </Form>
                    </Col>
                </Row>

            </Container>
        </React.Fragment>
    );
  }
}
