import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-spinkit';
import { WebSocketComponent } from "./websocket_component"


export {  VipDashboard };

class Block extends React.Component {
    
    render()
    {
        return (
            <div>{this.props.block}</div>
        );
    }
}


class PnlScrape extends React.Component
{
  render()
  {
     //<div className = 'perpetualPNLTag'>Perpetual PNL</div><div className = 'activeTraderTag'>Active Trader</div></div>
    //<div className = 'tableCell' style={{width: 170}}>{this.props.message.collection_timestamp}</div>
    return (
      <div className = 'tableRow'>
        <div className = 'tableCell' style={{width: 320}}>EBID: {this.props.message.ebid} <br/> {this.props.message.comment}</div>
        <div className = 'tableCell' style={{width: 150}}>{this.props.message.age}</div>
      </div>
      );
  }
}



class VipContainer extends React.Component
{
  render()
  {
    //console.log("VIP Container Render");
    //console.log(this.props.vips);

    let vipArry = [];

    try
    {
      vipArry = Object.values(this.props.vips);
    }
    catch (error)
    {
      console.error("Error parsing out the props in VIP Container");
    }

    vipArry.sort(function(a, b) {
      if(a.age < b.age)
      {
        return -1;
      }
      else
      {
        return 1;
      }
    })
      
    return (
      <div className = 'vipGroup'>
        {vipArry.map(vip => (<VipTile onNewSearch = {this.props.onNewSearch} vip={vip}></VipTile>))}  
      </div>
    );
  }
}



class VipTile extends React.Component
{

  formatTime(seconds)
  {
    if (seconds < 60)
    {
      return seconds + "s";
    }
    else if(seconds < 60*60)
    {
      let minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;

      return minutes + "m " + seconds + "s";
    }
    else if (seconds < 60*60*24)
    {
      let hours = Math.floor(seconds / (60*60));
      let minutes = Math.floor((seconds % 60*60)/60);

      return hours + "h " + minutes + "m ";
    }
    else
    {
      let days = Math.floor(seconds / (60*60*24));
      let hours = Math.floor((seconds % (60*60*24))/(60*60));

      return days + "d " + hours + "h ";
    }
  }

  clickevent(e)
  {
    this.props.onNewSearch(this.props.vip.ebid);
  }

  render()
  {

    let flashclass = 'vipTile flash';

    if ((this.props.vip.numUpdates % 2) == 1)
    {
      flashclass = 'vipTile flash2';
    }

    //Figure out how long ago the update timestamp is, and style the box based on it
    let currentTime = Date.now() / 1000;
    let timeSinceCollection = currentTime - this.props.vip.collection_timestamp;
    let freshnessClass = "FreshVIP";

    if(timeSinceCollection < 60*5)
    {
      freshnessClass = "FreshVIP";
    }
    else if(timeSinceCollection < 60*15)
    {
      freshnessClass = "NotFreshVIP";
    }
    else
    {
      freshnessClass = "OldVIP";
    }
    //console.log("Time Since Collection: " + timeSinceCollection);
    return (
        <div className = {flashclass} ageType = {this.props.vip.age_type} onClick = {this.clickevent.bind(this)}>
          {this.props.vip.ebid}<br></br>
          {this.props.vip.comment}<br></br>
          <b>USDT-M Trade:</b> {this.formatTime(this.props.vip.age)} ago,
          <b>Updated:</b> {this.formatTime(Math.round(timeSinceCollection))} ago<br></br>
          <b>COIN-M Trade:</b>Never,
          <b>Updated:</b>Never ago
        </div>
      );
  }
}


class ConnectionBar extends React.Component
{
  render()
  {
    return (
      <div className = 'connectionBar'> </div>
    );
  }
}

class VipDashboard extends React.Component {

    constructor(props)
    {
        console.log("Vip activity component loaded");
        super(props);
        this.state = {addr: this.props.addr, token: this.props.token, vipBuckets: {}, vipTypesArry: [], vipTypesDict: {}, pnlMessages: [], positionMessages: [], commandHistory: []};

        console.log("Token Is: " + this.state.token);
        if(this.state.token.length > 5)
      {
        
        this.fullVipUpdate(this.props.token);
      }
    }

  


  componentDidUpdate(prevProps)
  {
    //This means that the props update and we might have a token now
     
      if ((prevProps.token != this.props.token))
      {
        console.log("Updating token from " + this.state.token + " to " + this.props.token);
        this.setState({token: this.props.token});
        if (this.props.token != "")
        {
          this.fullVipUpdate(this.props.token);
        }
      }
  }

  fullVipUpdate(token)
  {
    console.log("Full VIP Update - Token - " + token);
     fetch("https://panopticonia.com/api/getvips.php?token=" + token)
        .then(response => response.json())
        .then((data) => 
          {
            console.log("Queried Initial VIP State");
            console.log(data);
            //let vips = JSON.parse(data);
           

            for (let i = 0; i < data.length; i++)
            {
              this.vipUpdateHandler(data[i]);
            }

          });

  }

  vipUpdateHandler(update)
  {
    //let lastCommand = JSON.parse(event.data);
    let lastCommand = update;

      //vipUpdatehander(event.data);
      let currentTime = Date.now();
      let lastTradeDate = Date(lastCommand.last_traded);
      let ebid = lastCommand.ebid;
      lastCommand.last_traded = lastTradeDate.toLocaleString();

      //console.log(lastCommand);
      lastCommand.numUpdates = 1; 
     


      //Cutoffs
      //Extremely Active: < 10 minutes
      //Active: < 1 Hour
      //Moderately Active: < 24 Hours
      //Somewhat Active: < 7 days
      //Idle: < 30 days
      //Very Idle: > 30 days

      if (lastCommand.age < (60*20))
      {
        lastCommand.age_type = 0;
      }
      else if(lastCommand.age < (60*60*24))
      {
        lastCommand.age_type = 1;
      }
      else if(lastCommand.age < (60*60*24*7))
      {
        lastCommand.age_type = 2;
      }
      else
      {
        lastCommand.age_type = 3;
      }


      //Update the VIP Types Dictionary if the most recent VIP message contains a new type of VIP user
      if (!(lastCommand.vip_tier in this.state.vipTypesDict))
      {
        let tmpVipTypesDict = this.state.vipTypesDict;
        tmpVipTypesDict[lastCommand.vip_tier] = {ebid: lastCommand};
        this.setState({vipTypesDict: tmpVipTypesDict});


        //Update the array of VIP types that we use
        let tmpVipTypes = [];
        for (const key of Object.keys(this.state.vipTypesDict))
        {
          tmpVipTypes.push(key);
        }
        this.setState({vipTypesArry: tmpVipTypes});
      }


      //Add this new data to the VIP Buckets Dictionary
      let tmpVipBuckets = this.state.vipBuckets;

      if (!(lastCommand.vip_tier in tmpVipBuckets))
      {
        tmpVipBuckets[lastCommand.vip_tier] = {};
      }

      if (ebid in tmpVipBuckets[lastCommand.vip_tier])
      {
        lastCommand.numUpdates = tmpVipBuckets[lastCommand.vip_tier][ebid].numUpdates + 1;
      }
      tmpVipBuckets[lastCommand.vip_tier][ebid] = lastCommand;
      this.setState({vipBuckets: tmpVipBuckets});

  }

    messageHandler = (event) =>
    {
      //this.setState({lastCommand: event.data});
      let lastCommand = JSON.parse(event.data);
      this.vipUpdateHandler(lastCommand);
      return;

      //vipUpdatehander(event.data);
      let currentTime = Date.now();
      let lastTradeDate = Date(lastCommand.last_traded);
      let ebid = lastCommand.ebid;
      lastCommand.last_traded = lastTradeDate.toLocaleString();

      //console.log(lastCommand);
      lastCommand.numUpdates = 1; 
     


      //Cutoffs
      //Extremely Active: < 10 minutes
      //Active: < 1 Hour
      //Moderately Active: < 24 Hours
      //Somewhat Active: < 7 days
      //Idle: < 30 days
      //Very Idle: > 30 days

      if (lastCommand.age < (60*20))
      {
        lastCommand.age_type = 0;
      }
      else if(lastCommand.age < (60*60*24))
      {
        lastCommand.age_type = 1;
      }
      else if(lastCommand.age < (60*60*24*7))
      {
        lastCommand.age_type = 2;
      }
      else
      {
        lastCommand.age_type = 3;
      }


      //Update the VIP Types Dictionary if the most recent VIP message contains a new type of VIP user
      if (!(lastCommand.vip_tier in this.state.vipTypesDict))
      {
        let tmpVipTypesDict = this.state.vipTypesDict;
        tmpVipTypesDict[lastCommand.vip_tier] = {ebid: lastCommand};
        this.setState({vipTypesDict: tmpVipTypesDict});


        //Update the array of VIP types that we use
        let tmpVipTypes = [];
        for (const key of Object.keys(this.state.vipTypesDict))
        {
          tmpVipTypes.push(key);
        }
        this.setState({vipTypesArry: tmpVipTypes});
      }


      //Add this new data to the VIP Buckets Dictionary
      let tmpVipBuckets = this.state.vipBuckets;

      if (!(lastCommand.vip_tier in tmpVipBuckets))
      {
        tmpVipBuckets[lastCommand.vip_tier] = {};
      }

      if (ebid in tmpVipBuckets[lastCommand.vip_tier])
      {
        lastCommand.numUpdates = tmpVipBuckets[lastCommand.vip_tier][ebid].numUpdates + 1;
      }
      tmpVipBuckets[lastCommand.vip_tier][ebid] = lastCommand;
      this.setState({vipBuckets: tmpVipBuckets});
    }




              //<div className = 'tableWrapperOuter'>
              //  <div className = 'tableWrapper'>
               //   <div className = 'tableHeader'>
                //    <div className = 'tableHeaderItem' style={{width: 320}}>VIP</div>
                 //   <div className = 'tableHeaderItem' style={{width: 150}}>Age</div>
                  //</div>
                 // <div className = 'tableBody'>
                  //  {this.state.pnlMessages.map(message => (<PnlScrape message={message}></PnlScrape>))}
                 // </div>
               // </div>
             // </div>
    
    render ()
    {
    
      console.log("VIP RENDER, BUCKETS + VIP TYPES");
      console.log(this.state.vipTypesArry);
      console.log(this.state.vipBuckets);

      return (
          <div className = "vipPageBottomContainer">
            <div className = 'block_header'>VIP Activity Monitor - {this.props.addr}</div>

            <div className = "vipPageBottomContainerInner">

              <WebSocketComponent addr={this.props.addr} messageHandler={this.messageHandler}></WebSocketComponent>
              
              <div className = 'vipContainerWrapper'>
               
                 {this.state.vipTypesArry.map(vipType => (<VipContainer onNewSearch = {this.props.onNewSearch} vips={this.state.vipBuckets[vipType]} groupNumber={vipType}>VIP Type {vipType}</VipContainer>))}
              </div>
            </div>
          </div>
       

          
         

      );
    }
}
