import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-spinkit';
import { WebSocketComponent } from "./websocket_component"
export { Debug };

class Block extends React.Component {
    
    render()
    {
        return (
            <div>{this.props.block}</div>
        );
    }
}


class PnlScrape extends React.Component
{
  render()
  {
     //<div className = 'perpetualPNLTag'>Perpetual PNL</div><div className = 'activeTraderTag'>Active Trader</div></div>
    //<div className = 'tableCell' style={{width: 170}}>{this.props.message.collection_timestamp}</div>
    return (
      <div className = 'tableRow'>
        <div className = 'tableCell' style={{width: 320}}>BID: {this.props.message.bid} <br/> EBID: {this.props.message.ebid}</div>
        <div className = 'tableCell' style={{width: 210}}>{this.props.message.username}</div>
        <div className = 'tableCell' style={{width: 150}}>
           {this.props.message.otherTags.map(classTag => (<div className={classTag[0]}>{classTag[1]}</div>))}
        </div>
      </div>
      );
  }
}

class Debug extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {addr: this.props.addr, token: this.props.token, pnlMessages: [], positionMessages: [], commandHistory: [], lastCommand: -1};
    }

    debugHandler = (event) =>
    {
      this.setState({lastCommand: event.data});
      let lastCommand = event.data;
      lastCommand = JSON.parse(lastCommand);
      let currentTime = Date.now();
      let timeDifference = currentTime - lastCommand.last_traded;

      console.log(lastCommand);
      lastCommand.otherTags = [];
      lastCommand.customMessage = '';

      if (lastCommand.scrape_type == 0)
      {
        lastCommand.otherTags.push(["perpetualPNLTag", "USD-(S)  PNL"]);
      }
      else if (lastCommand.scrape_type == 1)
      {
        lastCommand.otherTags.push(["deliveryPNLTag", "COIN-(M) PNL"]);
      }
      else if (lastCommand.scrape_type == 2)
      {
        lastCommand.otherTags.push(["perpetualPositionsTag", "USD-(S)  Position"]);
      }
      else if (lastCommand.scrape_type == 3)
      {
        lastCommand.otherTags.push(["deliveryPositionsTag", "COIN-(M) Position"]);
      }

      if (timeDifference < 60*60*24*3*1000)
      {
        lastCommand.otherTags.push(["activeTraderTag", "Active"]);
      }
      else if(timeDifference < 60*60*24*14*1000)
      {
        lastCommand.otherTags.push(["intermediateTraderTag", "Intermediate"]);
      }
      
      

      //This triggers when its PNL
      if ('username' in lastCommand)
      {  
        let tmpPnlMessages = this.state.pnlMessages;
        tmpPnlMessages.unshift(lastCommand);
        tmpPnlMessages = tmpPnlMessages.slice(0, Math.min(100, tmpPnlMessages.length));
        this.setState({pnlMessages: tmpPnlMessages});
      }
      else
      {
        if (lastCommand.quantity > 0)
        {
          lastCommand.username = "LONG " + lastCommand.pair;
        }
        else
        {
          lastCommand.username = "SHORT " + lastCommand.pair;
        }

        let tmpPnlMessages = this.state.pnlMessages;
        tmpPnlMessages.unshift(lastCommand);
        tmpPnlMessages = tmpPnlMessages.slice(0, Math.min(100, tmpPnlMessages.length));
        this.setState({pnlMessages: tmpPnlMessages});

      }
    }

    render ()
    {
      //if (this.state.token == "")
      //{
      //  return ( <Spinner height = '50px' width = '50px' name = 'cube-grid' />);
      //}
      //else
      //{
      return (
          <div className = "userPageBottomContainer">
            <div className = "userPageBottomContainerInner">

              <WebSocketComponent addr={this.props.addr} messageHandler={this.debugHandler}></WebSocketComponent>
              <div className = 'block_header'>Debug Window - {this.props.addr}</div>

              <div className = 'tableWrapperOuter'>
                <div className = 'tableWrapper'>
                  <div className = 'tableHeader'>
                    <div className = 'tableHeaderItem' style={{width: 320}}>Binance (Encrypted) ID</div>
                    <div className = 'tableHeaderItem' style={{width: 210}}>Username / Instrument</div>
                    <div className = 'tableHeaderItem' style={{width: 150}}>Tags</div>
                  </div>
                  <div className = 'tableBody'>
                    {this.state.pnlMessages.map(message => (<PnlScrape message={message}></PnlScrape>))}
                  </div>
                </div>
              </div>
            </div>
          </div>

      );
   
    }
}
