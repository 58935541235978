import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

export { Searchbar };

class Searchbar extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {};
    }

    clickevent(e)
    {
      console.log("Search Button Was Clicked: " + this.state.inputValue);
      this.props.onNewSearch(this.state.inputValue);
    }

    inputchange(e)
    {
      console.log("input value changed: " + e.target.value);
      this.setState({inputValue: e.target.value});
    }

    handleKeyDown(e)
    {
      if (e.key == 'Enter')
      {
        this.clickevent();
      }
    }

    render ()
    {
      
      return (
        <div className = "searchBar">
          <div className = "searchWrapper">
            <div className = "searchWrapperOuter">
              
              <div className = "searchWrapperInner">
        <svg width="16" height = "16" viewBox = "0 0 16 16" fill = "none" className = "searchIcon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6312 12.3466C9.68491 12.9703 8.55152 13.3334 7.33334 13.3334C4.01963 13.3334 1.33334 10.6471 1.33334 7.33337C1.33334 4.01967 4.01963 1.33337 7.33334 1.33337C10.647 1.33337 13.3333 4.01967 13.3333 7.33337C13.3333 8.70648 12.8721 9.97185 12.0961 10.983L14.9881 13.875L13.5739 15.2892L10.6312 12.3466ZM7.33342 11.3334C9.54256 11.3334 11.3334 9.54253 11.3334 7.33339C11.3334 5.12426 9.54256 3.33339 7.33342 3.33339C5.12428 3.33339 3.33342 5.12426 3.33342 7.33339C3.33342 9.54253 5.12428 11.3334 7.33342 11.3334Z" fill="#BABEC9"></path>
                </svg>
                <input className = "searchBox" onChange = {this.inputchange.bind(this)} onKeyDown = {this.handleKeyDown.bind(this)}></input>
              </div>
            </div>
            <div className = "searchBoxWrapper">
              <button className = "searchBoxButton" onClick = {this.clickevent.bind(this)}>Search BID / EBID</button>
            </div>
          </div>
        </div>
      );
      
    }
}
