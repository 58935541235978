import React, { useState} from 'react';

export {WebSocketComponent};

class WebSocketComponent extends React.Component {   

    constructor(props)
    {
        super(props);
        this.state = {socket: null, addr: this.props.addr, messageBuffer: [], buffer_size: 100};
    }

    componentDidMount()
    {
        let ws = new WebSocket(this.state.addr);
        ws.addEventListener('open', this.handleSocketOpen);
        ws.addEventListener('message', this.handleSocketMessage);
        ws.addEventListener('close', this.handleSocketClose);
        ws.addEventListener('error', this.handleSocketError);

        console.log("Websocket [" + this.state.addr + "] is connecting...");
        this.setState({socket: ws});
    }

    handleSocketMessage = (event) =>
    {
        this.props.messageHandler(event);
        let mb = this.state.messageBuffer;
        mb = mb.concat(event.data);
        mb = mb.slice(Math.max(0, mb.length - this.state.buffer_size));
        this.setState({messageBuffer: mb});
    }

    handleSocketOpen = (event) =>
    {
        this.state.socket.send("Hello Server");
    }

    handleSocketError = (event) =>
    {
      console.log("There was a websocket Error!");
    }

    handleSocketClose = (event) =>
    {
      console.log("Reconnecting websocket since we are in the close event");
      let ws = new WebSocket(this.state.addr);
      ws.addEventListener('open', this.handleSocketOpen);
      ws.addEventListener('message', this.handleSocketMessage);
      ws.addEventListener('close', this.handleSocketClose);
      ws.addEventListener('error', this.handleSocketError);
  
      this.setState({socket: ws});

    }

    render() {
        return (
            <div></div>
        );
    }
}
