import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Plot from 'react-plotly.js';
import SnapshotTable from './snapshot_table.js'

import Spinner from 'react-spinkit';
import { WebSocketComponent } from "./websocket_component"
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Popup from 'react-popup';

import { TableComponent } from "./table_component"

//https://minutemailer.github.io/react-popup/
export { Trader };

class CopyButton extends React.Component {
    
    render()
    {
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" color="#eff3f8" cursor="pointer"><g id="copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><circle id="Oval" fill="currentColor" cx="10" cy="10" r="10"></circle><g id="Group-13" transform="translate(5.794476, 5.790468)" stroke="#616B84"><path d="M3.5978014,6.21230639e-14 L6.00483293,6.21230639e-14 C6.24961697,6.2522187e-14 6.48589921,0.0897832911 6.66891449,0.252339862 L7.72628545,1.19151064 C7.93994388,1.38128488 8.06220389,1.65340132 8.06220389,1.93917078 L8.06220389,5.79807571 C8.06220389,6.35036046 7.61448864,6.79807571 7.06220389,6.79807571 L3.5978014,6.79807571 C3.04551665,6.79807571 2.5978014,6.35036046 2.5978014,5.79807571 L2.5978014,1 C2.5978014,0.44771525 3.04551665,6.22245169e-14 3.5978014,6.21230639e-14 Z" id="Rectangle"></path><path d="M5.46440249,7.1136292 L5.46440249,7.1136292 L5.46440249,7.70730424 C5.46440249,8.31088547 4.97510322,8.80018474 4.37152199,8.80018474 L1.0928805,8.80018474 C0.489299265,8.80018474 0,8.31088547 0,7.70730424 L0,3.09498952 C0,2.49140829 0.489299265,2.00210903 1.0928805,2.00210903 L2.34654139,2.00210903 L2.34654139,2.00210903" id="Path"></path><path d="M5.61108423,0 L5.61108423,1.84318671 C5.61108423,2.06410061 5.79017033,2.24318671 6.01108423,2.24318671 L8.06220389,2.24318671 L8.06220389,2.24318671" id="Path-2"></path></g></g></svg>

        );
    }
}

class Twitter extends React.Component {
    constructor(props)
    {
      super(props);
      this.state = {twitter: this.props.twitter}
    }

    render()
    {
      if(this.state.twitter != "")
      {
        return (
           <a href={this.state.twitter}>{this.state.twitter}</a>
        );
      }
      else
      {
        return (<div className="statisticLabel">Twitter<div className = "statisticValue">none</div></div>);
      }
    }
}

class TraderWagon extends React.Component {
    constructor(props)
    {
      super(props);
      this.state = {isSharing: this.props.isSharingTw, openId: this.props.openId, portfolioId: this.props.portfolioId}
    }

    render()
    {
        return (
           <div>{this.props.isSharing} | {this.props.openId} | {this.props.portfolioId}</div>
        );
    }
}

class Age extends React.Component {
    constructor(props)
    {
      super(props);
      this.state = {age: parseInt((Date.now()/1000 - this.props.timestamp) / 86400)};
    }

    render()
    {
        return (
           <div className="ageOuter"><div className="age"><img className = "calendarImage" src="https://panopticonia.com/scripts/calendar.svg"></img> {this.state.age} Days </div></div>
        );
    }
}

class PromptText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue
        };


        this.onChange = (e) => this._onChange(e);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.onChange(this.state.value);
        }
    }

    _onChange(e) {
        let value = e.target.value;

        this.setState({value: value});
    }

    render() {
        return <input type="text" placeholder={this.props.placeholder} className="mm-popup__input" value={this.state.value} onChange={this.onChange} />;
    }
}


class PromptNumber extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue
        };


        this.onChange = (e) => this._onChange(e);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.onChange(this.state.value);
        }
    }

    _onChange(e) {
        let value = e.target.value;

        this.setState({value: value});
    }

    render() {
        return <input type="number" min="0" step="1" placeholder={this.props.placeholder} className="mm-popup__input" value={this.state.value} onChange={this.onChange} />;
    }
}


class PromptParagraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue
        };


        this.onChange = (e) => this._onChange(e);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.onChange(this.state.value);
        }
    }

    _onChange(e) {
        let value = e.target.value;

        this.setState({value: value});
    }

    render() {
        return <textarea rows="4" height = "120px" type="text" placeholder={this.props.placeholder} className="mm-popup__area" value={this.state.value} onChange={this.onChange} />;
    }
}






class ClickableTabs extends React.Component {
  state = {
    activeIndex: 0
  }

  handleClick(index)
  {
    //console.log("child click handler: ", index);

    if (index == 1 || index == 2)
    {
      NotificationManager.warning('UI Feature Not Yet Implemented', 'Warning', 3000);
    }

    this.setState({activeIndex: index});
    this.props.handleTabSwitch(index);
  }

  render() {
    return <div className = 'tabContainerLeft'>
      <ClickableTab name="Graphs" index={0} isActive={ this.state.activeIndex===0 } onClick={ this.handleClick.bind(this) } />
      <ClickableTab name="Public Positions" index={1} isActive={ this.state.activeIndex===1 } onClick={ this.handleClick.bind(this) }/>
      <ClickableTab name="Snapshots" index={2} isActive={ this.state.activeIndex===2 } onClick={ this.handleClick.bind(this) }/>
      <ClickableTab name="Social Data" index={3} isActive={ this.state.activeIndex===3 } onClick={ this.handleClick.bind(this) }/>
    </div>
  }
}

class ClickableTab extends React.Component {
  handleClick = () => this.props.onClick(this.props.index)
  
  render() {
    return <div
      className={
        this.props.isActive ? 'tabButtonSelected' : 'tabButtonNonSelected'
      }
      onClick={ this.handleClick }
    >
      <span>{ this.props.name }</span>
    </div>
  }
}



class SocialDataTab extends React.Component {
  
  constructor(props)
  {
    super(props);
    this.state = {addr: this.props.addr, token: this.props.token, ebid: this.props.ebid, 
                  bannedTableOrderBy: "timestamp", bannedTableDir: "desc", bannedTableOffset: "0", bannedTableLimit: "10", bannedTableData: [['a', 'b', 'c'], ['1', '2', '3']], bannedTableColumnMapping: {"Timestamp": "timestamp", "Banned Status": "banned_status"}, bannedTableTitle: "User Ban / Block History", bannedTableSubtitle: "", bannedTableColumns: [['#', '25px', 'Hidden'], ['Timestamp', '100px', 'Default'], ['Banned Status', '100px', 'Default']],
                  usernameTableOrderBy: "timestamp", usernameTableDir: "desc", usernameTableOffset: "0", usernameTableLimit: "20", usernameTableData: [['a', 'b', 'c'], ['1', '2', '3']], usernameTableColumnMapping: {"Timestamp": "timestamp", "Username": "username"}, usernameTableTitle: "Username History", usernameTableSubtitle: "", usernameTableColumns: [['#', '25px', 'Hidden'], ['Timestamp', '100px', 'Default'], ['Username', '150px', 'Default']],
                  twitterTableOrderBy: "timestamp", twitterTableDir: "desc", twitterTableOffset: "0", twitterTableLimit: "10", twitterTableData: [['a', 'b', 'c'], ['1', '2', '3']], twitterTableColumnMapping: {"Timestamp": "timestamp", "Twitter ID": "twitter"}, twitterTableTitle: "Twitter History", twitterTableSubtitle: "", twitterTableColumns: [['#', '25px', 'Hidden'], ['Timestamp', '100px', 'Default'], ['Twitter ID', '150px', 'Default']],
                  photoTableOrderBy: "timestamp", photoTableDir: "desc", photoTableOffset: "0", photoTableLimit: "10", photoTableData: [['a', 'b', 'c'], ['1', '2', '3']], photoTableColumnMapping: {"Timestamp": "timestamp", "Photo URL / Identifier": "photo_url"}, photoTableTitle: "PFP / NFT History", photoTableSubtitle: "", photoTableColumns: [['#', '25px', 'Hidden'], ['Timestamp', '100px', 'Default'], ['Photo URL', '200px', 'Default'], ['Image', '300px', 'Default'], ['Image Collection Date', '100px', 'Default']],
                  introductionTableOrderBy: "timestamp", introductionTableDir: "desc", introductionTableOffset: "0", introductionTableLimit: "10", introductionTableData: [['a', 'b', 'c'], ['1', '2', '3']], introductionTableColumnMapping: {"Timestamp": "timestamp"}, introductionTableTitle: "Introduction Text History", introductionTableSubtitle: "", introductionTableColumns: [['#', '25px', 'Hidden'], ['Timestamp', '100px', 'Default'], ['Introduction Text', '600px', 'Default']],
                  traderWagonTableOrderBy: "timestamp", traderWagonTableDir: "desc", traderWagonTableOffset: "0", traderWagonTableLimit: "10", traderWagonTableData: [['a', 'b', 'c'], ['1', '2', '3']], traderWagonTableColumnMapping: {"Timestamp": "timestamp", 'TraderWagon ID': 'tw_id', 'Portfolio ID': 'portfolio_id', 'Is TraderWagon Trader?': 'istwtrader', 'Is Sharing TraderWagon?': 'is_tw_shared'}, traderWagonTableTitle: "TraderWagon Status History", traderWagonTableSubtitle: "", traderWagonTableColumns: [['#', '25px', 'Hidden'], ['Timestamp', '100px', 'Default'], ['TraderWagon ID', '80px', 'Default'], ['Portfolio ID', '80px', 'Default'], ['Is TraderWagon Trader?', '80px', 'Default'], ['Is Sharing TraderWagon?', '80px', 'Default']]

                  };

    if(this.state.token.length > 5)
    {  
      this.usernameTableUpdate(this.props.token, this.props.ebid);
      this.twitterTableUpdate(this.props.token, this.props.ebid);
      this.bannedTableUpdate(this.props.token, this.props.ebid);
      this.photoTableUpdate(this.props.token, this.props.ebid);
      this.introductionTableUpdate(this.props.token, this.props.ebid);
      this.traderWagonTableUpdate(this.props.token, this.props.ebid);
    }
  }


  componentDidUpdate(prevProps)
  {
    //This means that the props update and we might have a token now     
    if ((prevProps.token != this.props.token))
    {
      //console.log("Updating token from " + this.state.token + " to " + this.props.token);
      this.setState({token: this.props.token});
      if (this.props.token != "")
      {
        this.usernameTableUpdate(this.props.token, this.state.ebid, this.state.usernameTableOrderBy, this.state.usernameTableDir, this.state.usernameTableOffset);
        this.twitterTableUpdate(this.props.token, this.state.ebid, this.state.usernameTableOrderBy, this.state.usernameTableDir, this.state.usernameTableOffset);
        this.bannedTableUpdate(this.props.token, this.state.ebid, this.state.bannedTableOrderBy, this.state.bannedTableDir, this.state.bannedTableOffset);
        this.photoTableUpdate(this.props.token, this.state.ebid, this.state.photoTableOrderBy, this.state.photoTableDir, this.state.photoTableOffset);
        this.introductionTableUpdate(this.props.token, this.state.ebid, this.introductionTableOrderBy, this.state.introductionTableDir, this.state.introductionTableOffset);
        this.traderWagonTableUpdate(this.props.token, this.state.ebid, this.traderWagonTableOrderBy, this.state.traderWagonTableDir, this.state.traderWagonTableOffset);
      }
    }
  }

  usernameTableUpdate(token, ebid, orderby = "timestamp", dir = "desc", offset = "0", limit = "10")
  {
    fetch("https://panopticonia.com/api/query_username_history.php?token=" + token + "&ebid=" + ebid +  "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            let table_data = [];
            for (let i = 0; i < data.length; i++)
            {
              table_data.push([{data: parseInt(this.state.usernameTableOffset) + i, width: '25px', type: 'index'}, //The index
                              {data: parseFloat(data[i][2]), width: '100px', type: 'date'}, //Timestamp 
                              {data: data[i][1], width: '150px', type: 'username'}]); //Username
            } 
            this.setState({usernameTableData: table_data});
          });
  }

  twitterTableUpdate(token, ebid, orderby = "timestamp", dir = "desc", offset = "0", limit = "10")
  {
    fetch("https://panopticonia.com/api/query_twitter_history.php?token=" + token + "&ebid=" + ebid +  "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            let table_data = [];
            for (let i = 0; i < data.length; i++)
            {
              table_data.push([{data: parseInt(this.state.twitterTableOffset) + i, width: '25px', type: 'index'}, //The index
                              {data: parseFloat(data[i][2]), width: '100px', type: 'date'}, //Timestamp 
                              {data: data[i][1], width: '150px', type: 'username'}]); //Twitter ID
            } 
            this.setState({twitterTableData: table_data});
          });
  }

  bannedTableUpdate(token, ebid, orderby = "timestamp", dir = "desc", offset = "0", limit = "10")
  {
    fetch("https://panopticonia.com/api/query_banned_history.php?token=" + token + "&ebid=" + ebid +  "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            let table_data = [];
            for (let i = 0; i < data.length; i++)
            {
              table_data.push([{data: parseInt(this.state.bannedTableOffset) + i, width: '25px', type: 'index'}, //The index
                              {data: parseFloat(data[i][2]), width: '100px', type: 'date'}, //Timestamp 
                              {data: data[i][1], width: '100px', type: 'bool'}]); //Banned Status
            } 
            this.setState({bannedTableData: table_data});
          });
  }

  photoTableUpdate(token, ebid, orderby = "timestamp", dir = "desc", offset = "0", limit = "10")
  {
    fetch("https://panopticonia.com/api/query_photo_history.php?token=" + token + "&ebid=" + ebid +  "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            let table_data = [];
            for (let i = 0; i < data.length; i++)
            {
              table_data.push([{data: parseInt(this.state.photoTableOffset) + i, width: '25px', type: 'index'}, //The index
                              {data: parseFloat(data[i][1]), width: '100px', type: 'date'}, //Timestamp 
                              {data: data[i][0], width: '200px', type: 'url'}, //Photo Url
                              {data: data[i][2], width: '300px', type: 'image'}, //Photo Data
                              {data: data[i][3], width: '100px', type: 'date'}]); //Photo Data Query Date
                              
            } 
            this.setState({photoTableData: table_data});
          });
  }

  introductionTableUpdate(token, ebid, orderby = "timestamp", dir = "desc", offset = "0", limit = "10")
  {
    fetch("https://panopticonia.com/api/query_introduction_history.php?token=" + token + "&ebid=" + ebid +  "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            let table_data = [];
            for (let i = 0; i < data.length; i++)
            {
              table_data.push([{data: parseInt(this.state.introductionTableOffset) + i, width: '25px', type: 'index'}, //The index
                              {data: parseFloat(data[i][2]), width: '100px', type: 'date'}, //Timestamp 
                              {data: data[i][1], width: '600px', type: 'paragraph'}]); //Introduction
            } 
            this.setState({introductionTableData: table_data});
          });
  }

  
  traderWagonTableUpdate(token, ebid, orderby = "timestamp", dir = "desc", offset = "0", limit = "10")
  {
    fetch("https://panopticonia.com/api/query_traderwagon_history.php?token=" + token + "&ebid=" + ebid +  "&orderby=" + orderby + "&dir=" + dir + "&offset=" + offset + "&limit=" + limit)
      .then(response => response.json())
        .then((data) => 
          {
            let table_data = [];
            for (let i = 0; i < data.length; i++)
            {
              table_data.push([{data: parseInt(this.state.traderWagonTableOffset) + i, width: '25px', type: 'index'}, //The index
                              {data: parseFloat(data[i][3]), width: '100px', type: 'date'}, //Timestamp 
                              {data: data[i][1], width: '80px', type: 'username'}, //Trader Wagon ID
                              {data: data[i][2], width: '80px', type: 'username'}, //Portfolio ID
                              {data: data[i][4], width: '80px', type: 'bool'}, //Is TW Trader
                              {data: data[i][4], width: '80px', type: 'bool'}]); //Is TW Shared
                              
            } 
            this.setState({traderWagonTableData: table_data});
          });
  }




  bannedTableNextClick = () =>
  {
    let nextOffset = (parseInt(this.state.bannedTableOffset) + parseInt(this.state.bannedTableLimit)).toString();
    this.setState({bannedTableOffset: nextOffset});
    this.bannedTableUpdate(this.props.token, this.state.ebid, this.state.bannedTableOrderBy, this.state.bannedTableDir, nextOffset);
  }

  usernameTableNextClick = () =>
  {
    let nextOffset = (parseInt(this.state.usernameTableOffset) + parseInt(this.state.usernameTableLimit)).toString();
    this.setState({usernameTableOffset: nextOffset});
    this.usernameTableUpdate(this.props.token, this.state.ebid, this.state.usernameTableOrderBy, this.state.usernameTableDir, nextOffset);
  }

  twitterTableNextClick = () =>
  {
    let nextOffset = (parseInt(this.state.twitterTableOffset) + parseInt(this.state.twitterTableLimit)).toString();
    this.setState({twitterTableOffset: nextOffset});
    this.twitterTableUpdate(this.props.token, this.state.ebid, this.state.twitterTableOrderBy, this.state.twitterTableDir, nextOffset);
  }

  photoTableNextClick = () =>
  {
    let nextOffset = (parseInt(this.state.photoTableOffset) + parseInt(this.state.photoTableLimit)).toString();
    this.setState({photoTableOffset: nextOffset});
    this.photoTableUpdate(this.props.token, this.state.ebid, this.state.photoTableOrderBy, this.state.photoTableDir, nextOffset);
  }

  introductionTableNextClick = () =>
  {
    let nextOffset = (parseInt(this.state.introductionTableOffset) + parseInt(this.state.introductionTableLimit)).toString();
    this.setState({introductionTableOffset: nextOffset});
    this.introductionTableUpdate(this.props.token, this.state.ebid, this.state.introductionTableOrderBy, this.state.introductionTableDir, nextOffset);
  }

  traderWagonTableNextClick = () =>
  {
    let nextOffset = (parseInt(this.state.traderWagonTableOffset) + parseInt(this.state.traderWagonTableLimit)).toString();
    this.setState({traderWagonTableOffset: nextOffset});
    this.traderWagonTableUpdate(this.props.token, this.state.ebid, this.state.traderWagonTableOrderBy, this.state.traderWagonTableDir, nextOffset);
  }


  bannedTablePreviousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.bannedTableOffset) - parseInt(this.state.bannedTableLimit), 0)).toString();
    this.setState({bannedTableOffset: nextOffset});
    this.bannedTableUpdate(this.props.token, this.state.ebid, this.state.bannedTableOrderBy, this.state.bannedTableDir, nextOffset);
  }

  usernameTablePreviousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.usernameTableOffset) - parseInt(this.state.usernameTableLimit), 0)).toString();
    this.setState({usernameTableOffset: nextOffset});
    this.usernameTableUpdate(this.props.token, this.state.ebid, this.state.usernameTableOrderBy, this.state.usernameTableDir, nextOffset);
  }

  twitterTablePreviousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.twitterTableOffset) - parseInt(this.state.twitterTableLimit), 0)).toString();
    this.setState({twitterTableOffset: nextOffset});
    this.twitterTableUpdate(this.props.token, this.state.ebid, this.state.twitterTableOrderBy, this.state.twitterTableDir, nextOffset);
  }

  photoTablePreviousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.photoTableOffset) - parseInt(this.state.photoTableLimit), 0)).toString();
    this.setState({photoTableOffset: nextOffset});
    this.photoTableUpdate(this.props.token, this.state.ebid, this.state.photoTableOrderBy, this.state.photoTableDir, nextOffset);
  }

  introductionTablePreviousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.introductionTableOffset) - parseInt(this.state.introductionTableLimit), 0)).toString();
    this.setState({introductionTableOffset: nextOffset});
    this.introductionTableUpdate(this.props.token, this.state.ebid, this.state.introductionTableOrderBy, this.state.introductionTableDir, nextOffset);
  }
  
  traderWagonTablePreviousClick = () =>
  {
    let nextOffset = (Math.max(parseInt(this.state.traderWagonTableOffset) - parseInt(this.state.traderWagonTableLimit), 0)).toString();
    this.setState({traderWagonTableOffset: nextOffset});
    this.traderWagonTableUpdate(this.props.token, this.state.ebid, this.state.traderWagonTableOrderBy, this.state.traderWagonTableDir, nextOffset);
  }




  bannedTableSortClick = (column_name) =>
  {
    let sort_var = this.state.bannedTableColumnMapping[column_name];
    let sort_dir = 'DESC';

    if (sort_var == this.state.bannedTableOrderBy)
    {
      if(this.state.bannedTableDir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }
    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({bannedTableOrderBy: this.state.bannedTableColumnMapping[column_name]});
    this.setState({bannedTableDir: sort_dir});
    this.setState({bannedTableOffset: 0});
    this.bannedTableUpdate(this.props.token, this.state.ebid, sort_var, sort_dir, 0);
  }

  usernameTableSortClick = (column_name) =>
  {
    let sort_var = this.state.usernameTableColumnMapping[column_name];
    let sort_dir = 'DESC';

    if (sort_var == this.state.usernameTableOrderBy)
    {
      if(this.state.usernameTableDir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }
    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({usernameTableOrderBy: this.state.usernameTableColumnMapping[column_name]});
    this.setState({usernameTableDir: sort_dir});
    this.setState({usernameTableOffset: 0});
    this.usernameTableUpdate(this.props.token, this.state.ebid, sort_var, sort_dir, 0);
  }

  twitterTableSortClick = (column_name) =>
  {
    let sort_var = this.state.twitterTableColumnMapping[column_name];
    let sort_dir = 'DESC';

    if (sort_var == this.state.twitterTableOrderBy)
    {
      if(this.state.twitterTableDir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }
    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({twitterTableOrderBy: this.state.twitterTableColumnMapping[column_name]});
    this.setState({twitterTableDir: sort_dir});
    this.setState({twitterTableOffset: 0});
    this.twitterTableUpdate(this.props.token, this.state.ebid, sort_var, sort_dir, 0);
  }

  photoTableSortClick = (column_name) =>
  {
    let sort_var = 'test';
    try
    {
      sort_var = this.state.photoTableColumnMapping[column_name];
    }
    catch
    {
      return;
    }

    let sort_dir = 'DESC';

    if (sort_var == this.state.photoTableOrderBy)
    {
      if(this.state.photoTableDir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }
    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({photoTableOrderBy: this.state.photoTableColumnMapping[column_name]});
    this.setState({photoTableDir: sort_dir});
    this.setState({photoTableOffset: 0});
    this.photoTableUpdate(this.props.token, this.state.ebid, sort_var, sort_dir, 0);
  }

  introductionTableSortClick = (column_name) =>
  {
    let sort_var = "test";
    try
    {
      sort_var = this.state.introductionTableColumnMapping[column_name];
    }
    catch
    {
      return;
    }

    let sort_dir = 'DESC';

    if (sort_var == this.state.introductionTableOrderBy)
    {
      if(this.state.introductionTableDir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }
    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({introductionTableOrderBy: this.state.introductionTableColumnMapping[column_name]});
    this.setState({introductionTableDir: sort_dir});
    this.setState({introductionTableOffset: 0});
    this.introductionTableUpdate(this.props.token, this.state.ebid, sort_var, sort_dir, 0);
  }


  traderWagonTableSortClick = (column_name) =>
  {
    let sort_var = "test";
    try
    {
      sort_var = this.state.traderWagonTableColumnMapping[column_name];
    }
    catch
    {
      return;
    }

    let sort_dir = 'DESC';

    if (sort_var == this.state.traderWagonTableOrderBy)
    {
      if(this.state.traderWagonTableDir == "DESC")
      {
        sort_dir = 'ASC';
      }
    }
    //Change the parameters of what we are sorting by, and the direction, and build that into the columns
    this.setState({traderWagonTableOrderBy: this.state.traderWagonTableColumnMapping[column_name]});
    this.setState({traderWagonTableDir: sort_dir});
    this.setState({traderWagonTableOffset: 0});
    this.traderWagonTableUpdate(this.props.token, this.state.ebid, sort_var, sort_dir, 0);
  }


  render ()
  {
    return (
        <div className = "vipPageBottomContainer">

          <TableComponent previousClick = {this.usernameTablePreviousClick} nextClick = {this.usernameTableNextClick} title = {this.state.usernameTableTitle} subtitle = {"Username History [" + this.state.usernameTableOrderBy + "] [" + this.state.usernameTableDir + "] Offset [" + this.state.usernameTableOffset + "]"} columns = {this.state.usernameTableColumns} data = {this.state.usernameTableData} sort_click = {this.usernameTableSortClick}></TableComponent>
          <TableComponent previousClick = {this.twitterTablePreviousClick} nextClick = {this.twitterTableNextClick} title = {this.state.twitterTableTitle} subtitle = {"Twitter History [" + this.state.twitterTableOrderBy + "] [" + this.state.twitterTableDir + "] Offset [" + this.state.twitterTableOffset + "]"} columns = {this.state.twitterTableColumns} data = {this.state.twitterTableData} sort_click = {this.twitterTableSortClick}></TableComponent>
           <TableComponent previousClick = {this.photoTablePreviousClick} nextClick = {this.photoTableNextClick} title = {this.state.photoTableTitle} subtitle = {"PFP / NFT History [" + this.state.photoTableOrderBy + "] [" + this.state.photoTableDir + "] Offset [" + this.state.photoTableOffset + "]"} columns = {this.state.photoTableColumns} data = {this.state.photoTableData} sort_click = {this.photoTableSortClick}></TableComponent>
          <TableComponent previousClick = {this.bannedTablePreviousClick} nextClick = {this.bannedTableNextClick} title = {this.state.bannedTableTitle} subtitle = {"Banned History [" + this.state.bannedTableOrderBy + "] [" + this.state.bannedTableDir + "] Offset [" + this.state.bannedTableOffset + "]"} columns = {this.state.bannedTableColumns} data = {this.state.bannedTableData} sort_click = {this.bannedTableSortClick}></TableComponent>
          <TableComponent previousClick = {this.introductionTablePreviousClick} nextClick = {this.introductionTableNextClick} title = {this.state.introductionTableTitle} subtitle = {"Introduction History [" + this.state.introductionTableOrderBy + "] [" + this.state.introductionTableDir + "] Offset [" + this.state.introductionTableOffset + "]"} columns = {this.state.introductionTableColumns} data = {this.state.introductionTableData} sort_click = {this.introductionTableSortClick}></TableComponent>
          <TableComponent previousClick = {this.traderWagonTablePreviousClick} nextClick = {this.traderWagonTableNextClick} title = {this.state.traderWagonTableTitle} subtitle = {"TraderWagon History [" + this.state.traderWagonTableOrderBy + "] [" + this.state.traderWagonTableDir + "] Offset [" + this.state.traderWagonTableOffset + "]"} columns = {this.state.traderWagonTableColumns} data = {this.state.traderWagonTableData} sort_click = {this.traderWagonTableSortClick}></TableComponent>
        </div>
    );
  }

}




class Trader extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {bid: "40123456", ebid: this.props.ebid, token: this.props.token, pnl_plot: "", margin_plot: "", followers_plot: "", indicator_plot: "", hasLoaded: false, username: "um1ng", followers: 14, following: 8, twitter: "https://twitter.com/um1ng_eth", isSharingTw: true, openId: "0x1e87a", portfolioId: 5730, createdDate: 1609462800, lastTraded: 1676070185, activeTab: 0, tableData: [{update_time: 123, all_pnl: 457}, {update_time: "abc", all_pnl: "def"}], columns: [], tableData: [], extraData: {}};
;
    
        this.columns = [];
        this.tableData = [];
    }

    componentDidMount()
    {
      const script = document.createElement("script");
      script.src = "https://panopticonia.com/scripts/plotly-latest.min.js"
      script.async = true
      document.body.appendChild(script);

      if (this.props.token != "" && this.props.ebid != "")
      {
        fetch("https://panopticonia.com/api/viewuserjson_test.php?ebid=" + this.props.ebid + "&token=" + this.props.token)
          .then(response => response.json())
          .then((data) => 
            {
              this.setState({pnl_plot: JSON.parse(data[0]), margin_plot: JSON.parse(data[1]), net_deposits_plot: JSON.parse(data[2]), indicator_plot: JSON.parse(data[3]), followers_plot: JSON.parse(data[4]), following_plot: JSON.parse(data[5]), tableData: [{update_time: 111, all_pnl: 222}, {update_time: 333, all_pnl: 444}], extraData: data[9], hasLoaded: true}); 
              this.tableData = data[8];
              //console.log(data[9]['introduction']);
              //console.log(this.state.extraData);
            });


          this.tableData = [{update_time: 123, all_pnl: 457}, {update_time: "abc", all_pnl: "def"}];
          this.columns = [{Header: "Date", accessor: "update_time"}, {Header: "Daily PNL", accessor: "daily_pnl"}, {Header: "Daily ROI", accessor: "daily_roi"}, {Header: "Weekly PNL", accessor: "weekly_pnl"}, {Header: "Weekly ROI", accessor: "weekly_roi"}, {Header: "Monthly PNL", accessor: "monthly_pnl"}, {Header: "Monthly ROI", accessor: "monthly_roi"}, {Header: "All PNL", accessor: "all_pnl"}, {Header: "All ROI", accessor: "all_roi"}];
      }
    }

    updateNewUser(new_ebid, new_token)
    {
      this.setState({hasLoaded: false, ebid: new_ebid, token: new_token});

      fetch("https://panopticonia.com/api/viewuserjson_test.php?ebid=" + new_ebid + "&token=" + this.props.token)
        .then(response => response.json())
        .then((data) => 
          {
            this.setState({pnl_plot: JSON.parse(data[0]), margin_plot: JSON.parse(data[1]), net_deposits_plot: JSON.parse(data[2]), indicator_plot: JSON.parse(data[3]), followers_plot: JSON.parse(data[4]), following_plot: JSON.parse(data[5]), tableData: [{update_time: 111, all_pnl: 222}, {update_time: 333, all_pnl: 444}], extraData: data[9], hasLoaded: true});
            //this.tableData = [{update_time: 111, all_pnl: 222}, {update_time: "aaa", all_pnl: "bbb"}];
            this.tableData = data[8];
            //console.log(data[9]['introduction']);
            //console.log(this.state.extraData);
          });


        this.tableData = [{update_time: 123, all_pnl: 457}, {update_time: "abc", all_pnl: "def"}];
        this.columns = [{Header: "Date", accessor: "update_time"}, {Header: "Daily PNL", accessor: "daily_pnl"}, {Header: "Daily ROI", accessor: "daily_roi"}, {Header: "Weekly PNL", accessor: "weekly_pnl"}, {Header: "Weekly ROI", accessor: "weekly_roi"}, {Header: "Monthly PNL", accessor: "monthly_pnl"}, {Header: "Monthly ROI", accessor: "monthly_roi"}, {Header: "All PNL", accessor: "all_pnl"}, {Header: "All ROI", accessor: "all_roi"}];

    }

    componentDidUpdate(prevProps)
    {
      //console.log("Previous EBID: " + prevProps.ebid + " New EBID: " + this.props.ebid);
      //console.log("Previous token: " + prevProps.token + " New token: " + this.props.token);
      if ((prevProps.ebid != this.props.ebid)  || (prevProps.token != this.props.token) && this.props.ebid != "" && this.props.token != "")
      {
        this.updateNewUser(this.props.ebid, this.props.token);
      }
    }



    
    formatCollectionDate(ts)
    {
      var ts_date = new Date(ts * 1000);
      var ts_diff = Math.abs(Math.round((Date.now()/1000 - ts) / (60*60)))
      
      if (ts_diff < 72)
      {
        return ts_date.getDate() + "/" + (ts_date.getMonth()+1) + "/" + ts_date.getFullYear() + " " + ts_date.getHours() + ":" + ts_date.getMinutes() + " (" + ts_diff + " hours)";
      }
      else
      {
        ts_diff = Math.abs(Math.round((Date.now()/1000 - ts) / (60*60*24)))
        return ts_date.getDate() + "/" + (ts_date.getMonth()+1) + "/" + ts_date.getFullYear() + " " + ts_date.getHours() + ":" + ts_date.getMinutes() + " (" + ts_diff + " days)";
      }


    }

    handleTabSwitch(id)
    {
      //console.log("Parent tab click event: ", id);
      this.setState({activeTab: id});

      /*if (id == 0)
      {
        this.setState({activeTab: true});
      }
      else
      {
        this.setState({activeTab: false});
      }*/
    }
    


  submitTraderDetails()
  {

    Popup.registerPlugin('prompt', function (username, token, ebid, callback) {
      let nickname = username;
      let group_number = "0";
      let description = "";

      let nicknameChange = function (value) {
        nickname = value;
      };

      let groupChange = function (value) {
        group_number = value;
      };

      let descriptionChange = function(value) {
        description = value;
      };

      this.create({
          title: 'Update Trader Properties',
          content: <div>Nickname: <PromptText onChange={nicknameChange} placeholder={username} value={username}/> Group ID: <PromptNumber onChange={groupChange} placeholder={"0"} value={"0"}/> Description: <PromptParagraph onChange={descriptionChange} placeholder={""} value={""}/></div>, 
          buttons: {

              left: ['cancel'],
              right: [{
                  text: 'Save',
                  key: '⌘+s',
                  className: 'success',
                  action: function () {
                      callback(nickname, group_number, description, token, ebid);
                      //Submit the request to update the user account
                      Popup.close();
                  }
              }]
          }
      });
    });


    /** Call the plugin */
    Popup.plugins().prompt(this.state.extraData['nickname'], this.state.token, this.state.ebid, function (nickname, vip, comment, token, ebid) {
      //Popup.alert('You typed: ' + nickname + ' vip: ' + vip + ' comment: ' + comment);
      
      fetch("https://panopticonia.com/api/submit_trader_nickname.php?token=" + token + "&ebid=" + ebid +  "&nickname=" + nickname + "&vip=" + vip + "&comment=" + comment)
      .then(response => response.json())
        .then((data) => 
          {
            console.log(data);
          });
  
         Popup.alert("Trader Update Submitted!");
    });
  }


    render ()
    {
      if (this.state.token == "" || this.props.ebid == "")
      {
        return <div></div>
      }
      else if (!this.state.hasLoaded)
      {
        return (<Spinner margin = 'auto' height = '100px' width = '100px' name='cube-grid' />);
      }
      else
      {
        //Some preprocessing
        const pnlClass = this.state.extraData['all_pnl'] > 0 ? 'positive' : 'negative';

        var scrape_0_last_str = this.formatCollectionDate(this.state.extraData['scrape_0_last']);
        var scrape_0_scheduled_str = this.formatCollectionDate(this.state.extraData['scrape_0_scheduled']);
        var scrape_0_nonzero_str = this.formatCollectionDate(this.state.extraData['scrape_0_nonzero']);

        var scrape_1_last_str = this.formatCollectionDate(this.state.extraData['scrape_1_last']);
        var scrape_1_scheduled_str = this.formatCollectionDate(this.state.extraData['scrape_1_scheduled']);
        var scrape_1_nonzero_str = this.formatCollectionDate(this.state.extraData['scrape_1_nonzero']);

        var scrape_2_last_str = this.formatCollectionDate(this.state.extraData['scrape_2_last']);
        var scrape_2_scheduled_str = this.formatCollectionDate(this.state.extraData['scrape_2_scheduled']);
        var scrape_2_nonzero_str = this.formatCollectionDate(this.state.extraData['scrape_2_nonzero']);

        var scrape_3_last_str = this.formatCollectionDate(this.state.extraData['scrape_3_last']);
        var scrape_3_scheduled_str = this.formatCollectionDate(this.state.extraData['scrape_3_scheduled']);
        var scrape_3_nonzero_str = this.formatCollectionDate(this.state.extraData['scrape_3_nonzero']);



        return (
          <React.Fragment>
          <div className = "traderData" key = {this.props.ebid}>
          <div className = "traderDataTopContainerOuter">
            <div className = "traderDataTopContainerInner">
              <div className = "traderHeader">
                <div>
                  <div className = "traderHeaderLeft">
                    <div className = "avatarWrapper"><img height='100%' width='100%' src="https://panopticonia.com/scripts/default_picture.svg"></img></div>
                    <div className = "idInformation">
                      <div className = "traderID">{this.state.extraData['nickname']}<img  height = "16px" width = "16px" src="edit_button.svg" style={{margin: '8px', height: '100%', cursor: 'pointer'}} onClick={this.submitTraderDetails.bind(this)}></img></div>
                      <div className = "propertyRow">
                        <div className = "traderBIDContainer"> <div className = "traderBID">BID: {this.state.extraData['bid']}</div> <CopyButton></CopyButton></div>
                        <div className = "traderEBIDContainer"><div className = "traderEBID">EBID: {this.state.extraData['ebid']}</div><CopyButton></CopyButton></div>
                      </div>
                      <div className = "propertyRow"><Age timestamp={this.state.extraData['firstTradeTime']}></Age> <Age timestamp={this.state.extraData['lastTradeTime']}></Age></div>
                      <Twitter twitter={this.state.extraData['twitterUrl']}></Twitter>
                      <TraderWagon isSharingTw={this.state.extraData['twShared']} openId={this.state.extraData['openId']} portfolioId={this.state.extraData['portfolioId']}></TraderWagon>
                    </div>
                  </div>
                </div>

                <div className = "traderHeaderRight">
                  <div className = "traderPNLTop">
                    <div className = {`traderAllPNL ${pnlClass}`}>$ {this.state.extraData['all_pnl'].toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</div>
                    <div className = {`traderAllROI ${pnlClass}`}>{this.state.extraData['all_roe'].toFixed(2)}%</div>
                  </div>
                  <div className = "traderPNLBottom">
                    <div className = "traderMargin">$ {this.state.extraData['margin'].toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} USDT</div>
                  </div>
                </div>
              </div>
               
              <div className = "traderFooter"> 
                <div className = "traderFooterLeft">
                  <div className = 'propertyRow'>
                    <div className = 'statisticLabel'>Followers<div className = "statisticValue">{this.state.extraData['followers']}</div></div>
                    <div className = 'statisticLabel'>Following<div className = "statisticValue">{this.state.extraData['following']}</div></div>
                    
                    <div className = 'statisticLabel'>Snapshots<div className = "statisticValue">{this.state.extraData['snapshots']}</div></div>
                    <div className = 'statisticLabel'>Public Positions<div className = "statisticValue">{this.state.extraData['public_positions']}</div></div>
                  </div>
                  <div className = 'propertyRow'>
                    <div className = 'statisticLabel'>Sharpe Ratio<div className = "statisticValue">{this.state.extraData['sharpe']}</div></div> 
                    <div className = 'statisticLabel'>Banned<div className = 'statisticValue'>{Boolean(this.state.extraData['isBanned']).toString()}</div></div>
                    <div className = 'statisticLabel'>USD-M Positions Shared<div className = 'statisticValue'>{this.state.extraData['isSharingUSDM'].toString()}</div></div>
                    <div className = 'statisticLabel'>COIN-M Positions Shared<div className = 'statisticValue'>{this.state.extraData['isSharingCOINM'].toString()}</div></div>
                  </div>
                  <div className = 'propertyRow'>
                  </div>
                  
                  <div className = 'propertyRow'>
                    <div className = 'statisticLabel'>USD-M PNL:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className = 'statisticLabel'>Previous Collection<div className = "statisticValue">{scrape_0_last_str}</div></div>
                    <div className = 'statisticLabel'>Next Collection<div className = "statisticValue">{scrape_0_scheduled_str}</div></div>
                  </div>
                
                  <div className = 'propertyRow'>
                    <div className = 'statisticLabel'>COIN-M PNL:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className = 'statisticLabel'>Previous Collection<div className = "statisticValue">{scrape_1_last_str}</div></div>
                    <div className = 'statisticLabel'>Next Collection<div className = "statisticValue">{scrape_1_scheduled_str}</div></div>
                  </div>

                  <div className = 'propertyRow'>
                    <div className = 'statisticLabel'>USD-M Positions:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className = 'statisticLabel'>Previous Collection<div className = "statisticValue">{scrape_2_last_str}</div></div>
                    <div className = 'statisticLabel'>Next Collection<div className = "statisticValue">{scrape_2_scheduled_str}</div></div>
                  </div>

                  <div className = 'propertyRow'>
                    <div className = 'statisticLabel'>COIN-M Positions:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    <div className = 'statisticLabel'>Previous Collection<div className = "statisticValue">{scrape_3_last_str}</div></div>
                    <div className = 'statisticLabel'>Next Collection<div className = "statisticValue">{scrape_3_scheduled_str}</div></div>
                  </div>

                </div>
              </div>
            </div>
            <div className = "tabSelectContainer">
                <ClickableTabs handleTabSwitch={this.handleTabSwitch.bind(this)}/>
              
              <div className = "tabContainerRight" onClick={this.submitTraderDetails.bind(this)}>
                Refresh All Data
              </div>
            </div>
          </div>
          
          <div className = {(this.state.activeTab == 0) ? 'userPageBottomContainer' : 'invisible'}>
            <div className = "userPageBottomContainerInner">
              <div className = "traderPlots">
                  <div className = "plotContainer"><Plot data = {this.state.pnl_plot['data']} layout = {this.state.pnl_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
                  <div className = "plotContainer"><Plot data = {this.state.margin_plot['data']} layout = {this.state.margin_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
                  <div className = "plotContainer"><Plot data = {this.state.net_deposits_plot['data']} layout = {this.state.net_deposits_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
                  <div className = "plotContainer"><Plot data = {this.state.indicator_plot['data']} layout = {this.state.indicator_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
                  <div className = "plotContainer"><Plot data = {this.state.followers_plot['data']} layout = {this.state.followers_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
                  <div className = "plotContainer"><Plot data = {this.state.following_plot['data']} layout = {this.state.following_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
              </div>
            </div>
          </div>

          <div className = {(this.state.activeTab == 1) ? 'userPageBottomContainer' : 'invisible'}>
            <div className = "userPageBottomContainerInner">
              This is the public positions tab
            </div>
          </div>

          <div className = {(this.state.activeTab == 2) ? 'userPageBottomContainer' : 'invisible'}>
            <div className = "userPageBottomContainerInner">
              This is the snapshots tab
            </div>
          </div>

          <div className = {(this.state.activeTab == 3) ? 'userPageBottomContainer' : 'invisible'}>
            <div className = "userPageBottomContainerInner">
              <SocialDataTab token = {this.props.token} ebid = {this.props.ebid}></SocialDataTab>
            </div>
          </div>
            <NotificationContainer/>
          </div>

          
           
         
          </React.Fragment>
        );
        // <SnapshotTable columns={this.columns} data={this.tableData}/>
      }
    }
}
