import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

export { Navbar };

class Navbar extends React.Component {

    constructor(props)
    {
      super(props);
      this.state = {activeButton: "VipDashboard"};
    }

    clickevent(buttonName)
    {
      console.log("link was clicked");
      console.log(buttonName);
      this.setState({activeButton: buttonName});
      this.props.onNavButtonPress(buttonName);
    }

    render ()
    {
      
      return (
        <div className = "sideBar">
          <div className = "sidebarContainer">
            <a onClick={this.clickevent.bind(this, "TraderPage")} className = {(this.state.activeButton == 'TraderPage') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>Trader History</a>
            <a onClick={this.clickevent.bind(this, "VipActivity")} className = {(this.state.activeButton == 'VipActivity') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>VIP Activity</a>
            <a onClick={this.clickevent.bind(this, "VipDashboard")} className = {(this.state.activeButton == 'VipDashboard') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>VIP Dashboard</a>
            <a onClick={this.clickevent.bind(this, "Leaderboard")} className = {(this.state.activeButton == 'Leaderboard') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>Trader List & PNL</a>
            <a onClick={this.clickevent.bind(this, "LongShortRatios")} className = {(this.state.activeButton == 'LongShortRatios') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>Aggregate Analysis & Plots</a>
            <a onClick={this.clickevent.bind(this, "CollectionFeed")} className = {(this.state.activeButton == 'CollectionFeed') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>Collection Feed & Monitoring</a>
            <a onClick={this.clickevent.bind(this, "SearchTool")} className = {(this.state.activeButton == 'SearchTool') ? 'sidebarButtonActive' : 'sidebarButtonInactive'}>Search Tool</a>
          </div>
        </div>
      );
      
    }
}
