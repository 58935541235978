import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { WebSocketComponent } from "./websocket_component"
export { Firehose };


class Block extends React.Component {
  render()
  {
      return (
          <div>{this.props.block} {this.props.height}
          <ul>
            {this.props.swaps.map(({asset_1_tag, asset_2_tag, tx_index, gas_rate}) => (<div className = "swap_row">{asset_1_tag} -> {asset_2_tag}: {tx_index} - {gas_rate}</div>))}
          </ul>
        </div>
      );
  }
}


class Firehose extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {commandHistory: [], blocks: [], lastCommand: -1};
    }

    processSwapUpdate = (values) =>
    {
      let current_time = values['current_time'];
      let swap_timestamp = values['swap_timestamp'];
      let block_height = values['block_height'];
      let block_hash = values['block_hash'];
      let chain = values['chain'];
      let marketplace = values['marketplace'];
      let asset_1_tag = values['asset_1_tag'];
      let asset_2_tag = values['asset_2_tag'];
      let address = values['address'];
      let action = values['event'];
      let arg1 = values['arg1'];
      let arg2 = values['arg2'];
      let arg3 = values['arg3'];
      let arg4 = values['arg4'];
      let txhash = values['txhash'];
      let gas_consumed = values['gas_consumed'];
      let gas_rate = values['gas_rate'];
      let sender_address = values['sender_address'];
      let tx_index = values['tx_index'];
      let tx_status = values['tx_status'];
      let swap_token = values['swap_token'];

      let blocks = this.state.blocks;

      for (var i = 0; i < blocks.length; i++)
      {
        if (blocks[i]['block_height'] == block_height.toString())
        {
          blocks[i]['swaps'].unshift(values);
          this.setState({blocks: blocks})
        }
      }
    }

    processHeadUpdate = (values) =>
    {
      let ts1 = values[0];
      let ts2 = values[1];
      let block_height = values[2];
      let chain = values[3];

      let block = {"block_height": block_height.toString(), "chain": chain, "ts1": ts1.toString(), "ts2": ts2.toString(), "swaps": []};
      let blocks = this.state.blocks;
      
      blocks.unshift(block);
      blocks = blocks.slice(0, Math.min(200, blocks.length));
      this.setState({blocks: blocks});
      console.log("Blocks:" + blocks.toString())
    }


    firehoseHandler = (event) =>
    {
      this.setState({lastMessage: event.data});
      var parsed_arg = JSON.parse(event.data);
      var commands = parsed_arg['commands'];
      var swap_timestamp = 0;

      for (var i = 0; i < commands.length; i++)
      {
        var index = commands[i]['index'];
        let command_type = commands[i]['type'];
        //console.log(command_type);

        var tmp_commandHistory= this.state.commandHistory;
        tmp_commandHistory.unshift(commands[i]['type'] + " - " + commands[i]['index'].toString());
        tmp_commandHistory = tmp_commandHistory.slice(0, Math.min(30, tmp_commandHistory.length));
        this.setState({commandHistory: tmp_commandHistory, lastCommand: commands[i]['type']});

        if (command_type == "ping")
        {
          var one = 1;
          //console.log("Debug Ping");
        }
        else if (command_type == "swap_update")
        {
          this.processSwapUpdate(commands[i]['value']);
          console.log("swap_update");
        }
        else if (command_type == "head_update")
        {
          this.processHeadUpdate(commands[i]['value']);
          console.log("head_update");
        }
        else if (command_type == "forked_block")
        {
          console.log("forked_block");
        }
        
      }
        
      //console.log("Grid Message Handler: ", event.data);
      //console.log("Commands: " + commands.length.toString());
    }

    render ()
    {
        return (
            <div key={this.props.key} className={this.props.className}>
            <WebSocketComponent addr={'ws://jeju.andrew.cmu.edu:8768'} messageHandler={this.firehoseHandler}></WebSocketComponent>
            <div className = 'block_header'>Firehose</div>
            <ul className = 'NotDraggable swap_row container_body'>
            {this.state.blocks.map(({block_height, chain, ts1, ts2, swaps}) => (<Block block={chain} height={block_height} swaps={swaps}>This is a block</Block>))}
            </ul>
            </div>
        );
    }
}
