import React, { useState} from 'react';
import {summarize_string, relative_date} from './utilities';

export {TableComponent};


class SortIcon extends React.Component {
  constructor(props)
  {
    super(props);
  }

  render()
  {
    if(this.props.dir == "Default")
    {
      return (
        <div className = "SortIcon" onClick={this.props.sort_click.bind(this, this.props.column_name)}>
          <svg className = "UpSortIcon">
            <path className = "UpSortPath">
            </path>
          </svg>
          <svg className = "DownSortIcon">
            <path className = "DownSortPath">
            </path>
          </svg>
        </div>
      );
    }
    else if(this.props.dir == "Hidden")
    {
      return (<div></div>);
    }
    else if(this.props.dir == "ASC")
    {
      return (
        <div className = "SortIcon" onClick={this.props.sort_click.bind(this)}>
          <svg className = "UpSortIcon">
            <path className = "UpSortPath ActiveSortIcon">
            </path>
          </svg>
          <svg className = "DownSortIcon ActiveSortIcon">
            <path className = "DownSortPath">
            </path>
          </svg>
        </div>
      );

    }
    else if(this.props.dir == "DESC")
    {
      return (
        <div className = "SortIcon" onClick={this.props.sort_click.bind(this)}>
          <svg className = "UpSortIcon">
            <path className = "UpSortPath">
            </path>
          </svg>
          <svg className = "DownSortIcon">
            <path className = "DownSortPath">
            </path>
          </svg>
        </div>
      );
    }
  }
}

class TableColumn extends React.Component {
    constructor(props)
    {
      super(props);  
    }

    render()
    {
        return (
          <div className = "TableColumnDiv" style = {{width: this.props.width}}>{this.props.column_name}
            <SortIcon dir = {this.props.sort_dir} sort_click = {this.props.sort_click} column_name = {this.props.column_name}></SortIcon> 
          </div>
        );
    }
}

class TableCell extends React.Component {
  constructor(props)
  {
    super(props);
  }

  dummyUserSearch(ebid)
  {
    return;
  }

  render()
  {
    if(this.props.type == 'encrypted_id')
    {
      let summarized_string = summarize_string(this.props.data);
      summarized_string = this.props.data;
      let userSearch = this.dummyUserSearch;
      let ebid = '';

      try
      {
        userSearch = this.props.extras.userSearch;
        ebid = this.props.extras.ebid;
      }
      catch
      {
        console.log(this.props.type);
      }

      
      return (
        <div className = "TableCellDiv" style = {{width: this.props.width}}>{summarized_string}
          <img  height = "16px" width = "16px" src="edit_button.svg" style={{margin: '8px', height: '100%', cursor: 'pointer'}}></img>
          <img  height = "16px" width = "16px" src="search_icon.svg" style={{margin: '0px', height: '100%', cursor: 'pointer'}} onClick={userSearch.bind(this, ebid)} ></img>
          
          
        </div>
      )
    }
    else if(this.props.type == 'bool')
    {
      return (
        <div className = "TableCellDiv" style = {{width: this.props.width}}>{(this.props.data == true) ? "True" : "False"}</div>
      )
    }
    else if(this.props.type == 'username')
    {
       return (
        <div className = "TableCellDiv" style = {{width: this.props.width}}>{this.props.data}</div>
      )

    }
    else if(this.props.type == 'url')
    {
       return (
        <div className = "TableCellDiv" style = {{width: this.props.width, "word-break": "break-all"}}>{this.props.data}</div>
      )
    }
    else if(this.props.type == 'paragraph')
    {
       return (
        <div className = "TableCellDiv" style = {{width: this.props.width, "word-break": "break-all"}}>{this.props.data}</div>
      )
    }
    
    else if(this.props.type == 'index')
    {
       return (
        <div className = "TableCellDiv" style = {{width: this.props.width}}>{this.props.data}</div>
      )
    }
    else if(this.props.type == 'image')
    {
      //var base64String = Buffer.from(this.props.data, 'hex').toString('base64')
      var base64String = btoa(this.props.data.match(/\w{2}/g).map(function(a){return String.fromCharCode(parseInt(a, 16));} ).join(""))
      return (
        <div className = "TableCellDiv" style = {{width: this.props.width, padding: '8px'}}><img style = {{height: '200px'}} src={"data:image/png;base64," + base64String}></img></div>
      )
    }
    else if(this.props.type == 'pnl')
    {
      if(this.props.data == 0.0)
      {
        return (
          <div className = "TableCellDiv" style = {{width: this.props.width}}>{this.props.data.toLocaleString('en-US', {maximumFractionDigits: 0})}</div>
        )
      }
      else if(this.props.data > 0)
      {
         return (
          <div className = "TableCellDiv PosPnl" style = {{width: this.props.width}}>{this.props.data.toLocaleString('en-US', {maximumFractionDigits: 0})}</div>
         )
        
      }
      else
      {
        return (
          <div className = "TableCellDiv NegPnl" style = {{width: this.props.width}}>{this.props.data.toLocaleString('en-US', {maximumFractionDigits: 0})}</div>
        )
      }
    }
    else if (this.props.type == 'date')
    {
      let date = new Date(this.props.data * 1000);
      let date_string = date.toLocaleDateString('en-US') + " " + date.toLocaleTimeString('en-US');

      return (
         <div className = "TableCellDiv" style = {{width: this.props.width}}>{date_string}</div>
      )
    }
    else if (this.props.type == 'date_relative')
    {
      let relative_date_string = relative_date(this.props.data);

      return (
         <div className = "TableCellDiv" style = {{width: this.props.width}}>{relative_date_string}</div>
      )
    }
    else if (this.props.type == 'margin')
    {
      return (
         <div className = "TableCellDiv" style = {{width: this.props.width}}>{this.props.data.toLocaleString('en-US', {maximumFractionDigits: 0})}</div>
      )
    }
    else
    {
      return (
         <div className = "TableCellDiv" style = {{width: this.props.width}}>UKNOWN DATA TYPE</div>
      )

    }
  }
}

class TableRow extends React.Component {
    constructor(props)
    {
      super(props);
    }

    //{this.props.row.map(cell => <div className = "TableCellDiv" style = {{width: cell['width']}}>{cell['data']}</div>)}
    render()
    {
        return (
          <div className = "TableRowWrapperDiv">
            <div className = "TableRowDiv">
              {this.props.row.map(cell => (<TableCell data={cell['data']} width={cell['width']} extras={cell['extras']} type={cell['type']}></TableCell>))} 
              
              
            </div>
          </div>
        );
    }
}


class TableComponent extends React.Component {   

    constructor(props)
    {
        super(props);
        this.state = {title: this.props.title, subtitle: this.props.subtitle, columns: this.props.columns, data: this.props.data}
        //this.state = {title: 'This is a sample table', subtitle: '', columns: ['Token', 'Price', 'Amount', 'USD Value'], data: [['WBTC', '$43,927.80', '0.1467', '$6,442.02'], ['ETH', '$2,345.85', '0.1060', '$248.64'], ['GMX', '$1.05', '10.0', '$123.12']]};
    }

    render() {
      return (
        <div>
          <div className = "TableTitleDiv">{this.props.title}</div>
          <div className = "TableOuterDiv">
            <div className = "TableSubtitleDiv">
              <div className = "TableSubtitleBoxDiv">
                <div className = "TableSubtitleMarker">
                  {this.props.subtitle}
                </div>
              </div>
            </div>

            <div className = "TableButtonContainer">
              <div className = "TableButton" onClick={this.props.previousClick.bind(this)}>{"< Previous"}</div>
              <div className = "TableButton" onClick={this.props.nextClick.bind(this)}>{"Next >"}</div>
            </div>
             
           
            <div className = "TableInnerDiv">

              <div className = "TableHeaderDiv">
                {this.props.columns.map(column_name => (<TableColumn column_name={column_name[0]} width={column_name[1]} sort_dir ={column_name[2]} sort_click = {this.props.sort_click}></TableColumn>))} 
              </div>
                
              <div className = "TableBodyDiv">
                {this.props.data.map(row => (<TableRow row={row}></TableRow>))} 
              </div>
            </div>
          </div>
        </div>
      );
    }
}
