import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Plot from 'react-plotly.js';
import SnapshotTable from './snapshot_table.js'

import Spinner from 'react-spinkit';
import { WebSocketComponent } from "./websocket_component"
import {NotificationContainer, NotificationManager} from 'react-notifications';

export { TraderActivity };




class TraderActivityForm extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {ebid: this.props.ebid, token: this.props.token, pair: this.props.pair, age: this.props.age, accounts: "", activity_plot: ""};
  }

  clickevent(e)
  {
    this.props.submitForm(this.state.ebid, this.state.token, this.state.pair, this.state.age);
  }

  tokenchange(e)
  {
    if(e.target.value)
    {
      this.setState({pair: e.target.value});
    }
  }

  agechange(e)
  {
    if(e.target.value)
    {
      this.setState({age: e.target.value});
    }
  }

  accountschange(e)
  {
    if (e.target.value)
    {
      this.setState({ebid: e.target.value});
    }
  }

    
  handleKeyDown(e)
  {
    if (e.key == 'Enter')
    {
      this.clickevent();
    }
  }  


  render ()
  {
    return (
      <React.Fragment>
      <div className = "traderData" key = {this.props.ebid}>
        <div className = "traderDataTopContainerOuter">
          <div className = "traderDataTopContainerInner">
            <div className = "flex-row">

              <div className = "labeled-input-wrapper">
                Instrument
                <div className = "labeled-input">
                  <div className = "searchWrapperInner">
                    <input className = "searchBox" maxlength='10' onChange = {this.tokenchange.bind(this)} onKeyDown = {this.handleKeyDown.bind(this)}></input>
                  </div>
                </div>
              </div>

              <div className = "labeled-input-wrapper">
                Length (Days)
                <div className = "labeled-input">
                  <div className = "searchWrapperInner">
                    <input className = "searchBox" maxlength='10' onChange = {this.agechange.bind(this)} onKeyDown = {this.handleKeyDown.bind(this)}></input>
                  </div>
                </div>
              </div>

            </div>

            <div className = "large-input-wrapper">
              Accounts (Comma Separated)
              <div className = "large-labeled-input">
                <div className = "searchWrapperInner">
                <textarea className = "searchBox" rows='5' cols='200' resize='none' onChange = {this.accountschange.bind(this)} onKeyDown = {this.handleKeyDown.bind(this)}></textarea>
                </div>
              </div>
            </div>

            <button className = "inputFormButton" onClick = {this.clickevent.bind(this)}>Generate Activity Plot</button>

          </div>
        </div>
      </div>

      </React.Fragment>
    );
  }
}


class TraderActivityChart extends React.Component {

 constructor(props)
  {
    super(props);
    this.state = {activity_plot: ""};
  }

  render()
  {
    if(this.props.activity_plot == "")
    {
      return (<div></div>);
    }
    else
    {
      return(
      <div className = {'userPageBottomContainer'}>
        <div className = "userPageBottomContainerInner">
          <div className = "traderPlots">
            <div className = "plotContainer"><Plot data = {this.props.activity_plot['data']} layout = {this.props.activity_plot['layout']} useResizeHandler={true} style={{width: '100%', height: '100%'}}/></div>
          </div>
        </div>
      </div>
      );
    }
  }
}


class TraderActivity extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {activity_plot: ""};
    }

    componentDidMount()
    {
      const script = document.createElement("script");
      script.src = "https://panopticonia.com/scripts/plotly-latest.min.js"
      script.async = true
      document.body.appendChild(script);
      this.setState({hasLoaded: true});
    }

    updateNewUser(new_ebid, new_token, pair, age)
    {
      //console.log("Update new user called");
      this.setState({hasLoaded: false, ebid: new_ebid, token: new_token});

      fetch("https://panopticonia.com/api/viewuseractivity.php?ebid=" + new_ebid + "&token=" + this.props.token + "&pair=" + pair + "&age=" + age)
        .then(response => response.json())
        .then((data) => 
          {
            this.setState({activity_plot: JSON.parse(data[0]), hasLoaded: true});
          });
    }

    
    render ()
    {
      if (!this.state.hasLoaded)
      {

        return (
          <div>
            <TraderActivityForm ebid={this.state.ebid} token={this.state.token} pair={'BTCUSDT'} age={5} submitForm={this.updateNewUser.bind(this)}></TraderActivityForm>
            <Spinner margin = 'auto' height = '100px' width = '100px' name='cube-grid' />)
          </div>
        );
      }

      else
      {
        return (
        <div>
          <TraderActivityForm ebid={this.state.ebid} token={this.state.token} pair={'BTCUSDT'} age={5} submitForm={this.updateNewUser.bind(this)}></TraderActivityForm>
          <TraderActivityChart activity_plot={this.state.activity_plot}></TraderActivityChart>
        </div>
        );       
      }
    }
}
