import {intlFormatDistance} from 'date-fns'

export function summarize_string(s)
{
  //This takes in a string and shows the first 4 and last 4 characters as a summary
  
  
  if(s.length < 8)
  {
    return s;
  }

  let start = s.substring(0, 4);
  let end = s.substring(s.length - 3);

  return start + "..." + end;
}

export function relative_date(ts)
{

  try
  {
    return intlFormatDistance(ts*1000, new Date());
  } catch (error)
  {
    return "Unknown";
  }
}
