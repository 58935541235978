import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';


import GridLayout from "react-grid-layout";
import { Responsive, WidthProvider } from "react-grid-layout";
import Popup from 'react-popup';

import { Firehose } from "./scrape_firehose"
import { Debug } from "./debug_component"
import { VipDashboard } from "./vip_dashboard"
import { LeaderboardDashboard } from "./leaderboard.js"
import { Trader } from "./trader_component"
import { TraderActivity } from "./trader_activity"
import { Navbar } from "./nav_component"
import { Searchbar } from "./search_bar_component"
import { Login } from "./Login"

import 'bootstrap/dist/css/bootstrap.min.css';
import "./stylesheet.css"
import "./popup.css"
import 'react-notifications/lib/notifications.css';


class Reactapp extends React.Component {

    constructor(props)
    {
        super(props);
        //this.state = {ebid: "6E645C6A4B5139CF6922BC27612734A9"};
        this.state = {ebid: "", navButton: "VipDashboard", token: "", localToken: ""};
        
        var saved_token = localStorage.getItem('sessionToken');
        
        fetch("https://panopticonia.com/api/check_token.php?token=" + saved_token)
          .then((response) => response.text())
          .then(data =>
        {
          //console.log('response');
          //console.log(data);
          if(data == "True")
          {
            //console.log("Its true, setting token to " + saved_token);
            this.setState({token: saved_token});
          }
          else
          {
            //console.log("Token invalid, removing!");
            localStorage.removeItem('sessionToken');
          }
        });
    }

    userSearched(new_ebid)
    {
      //console.log("a new search for: " + new_ebid);
      this.setState({ebid: new_ebid, navButton: "TraderPage"});
    }

    navButtonPress(buttonName)
    {
      //console.log("Nav Button Press Of: " + buttonName);
      this.setState({navButton: buttonName});
    }

    newToken(token)
    {
      //console.log("New Token: " + token);
      this.setState({token: token});
      localStorage.setItem('sessionToken', token);
    }

    render ()
    {  
      return ((
                <div className = 'topDiv'> 
                  <Popup/>
                <div className = {(this.state.token == "") ? 'loginPage': 'invisible'}>
                  <Login className = 'loginPage' onLogin={this.newToken.bind(this)}></Login>
                </div>
                <div className = {(this.state.token == "") ? 'invisible': 'topDiv'}>
                    <Navbar className = 'navBar' onNavButtonPress={this.navButtonPress.bind(this)}></Navbar>
                      <div className = 'mainContainer'>
                        <Searchbar className = 'searchBar' onNewSearch={this.userSearched.bind(this)}></Searchbar>
                        <div className = {(this.state.navButton == 'TraderPage') ? 'traderPage' : 'invisible'}>
                          <Trader className = 'traderPage' ebid={this.state.ebid} token={this.state.token}></Trader>
                        </div>
                        <div className = {(this.state.navButton == 'CollectionFeed') ? 'collectionFeed' : 'invisible'}>
                          <Debug addr='wss://panopticonia.com:8765' token = {this.state.token}></Debug>
                        </div>
                        <div className = {(this.state.navButton == 'VipDashboard') ? 'vipDashboard' : 'invisible'}>
                          <VipDashboard addr='wss://panopticonia.com:8766' token = {this.state.token} onNewSearch={this.userSearched.bind(this)}>></VipDashboard>
                        </div>
                        
                        <div className = {(this.state.navButton == 'VipActivity') ? 'vipActivity' : 'invisible'}>
                          <TraderActivity className = 'traderPage' ebid={this.state.ebid} token={this.state.token} navButton={this.state.navButton} pair={'BTCUSDT'} age={5}></TraderActivity>
                        </div>

                        <div className = {(this.state.navButton == 'Leaderboard') ? 'leaderboard' : 'invisible'}>
                          <LeaderboardDashboard addr='wss://panopticonia.com:8766' token = {this.state.token} onNewSearch={this.userSearched.bind(this)}></LeaderboardDashboard>
                        </div>
                        
                      </div>
                  </div>
                </div>
              ));

      
    }
}


ReactDOM.render(<Reactapp></Reactapp>, document.body);
